var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ContainerCard',{attrs:{"locationMsg":_vm.locationMsg,"width":"70"}},[_c('div',{staticClass:"IssueDemand"},[_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.ruleForm,"rules":_vm.rules,"label-width":"100px"}},[_c('div',{staticClass:"IssueDemand-title"},[_c('img',{attrs:{"src":require("../../assets/images/信息资讯.png"),"alt":""}}),_c('span',[_vm._v("发布需求")])]),_c('el-form-item',{style:({
          ..._vm.formItemStyle,
          'border-bottom': _vm.focusKey === 'name' ? '1px solid #2885FF' : '0px ',
        }),attrs:{"label":"需求名称","prop":"name"}},[_c('el-input',{attrs:{"placeholder":_vm.placeholderName.demandName},on:{"focus":function($event){return _vm.handleFocus('name')},"blur":_vm.handleBlur},model:{value:(_vm.ruleForm.name),callback:function ($$v) {_vm.$set(_vm.ruleForm, "name", $$v)},expression:"ruleForm.name"}})],1),_c('div',{staticClass:"lineBox"}),_c('el-form-item',{staticClass:"technology",style:({
          'border-bottom':
            _vm.focusKey === 'tech_field' ? '1px solid #2885FF' : '0px ',
          padding: '24px 0px 12px 0px',
          'margin-bottom': '0px',
        }),attrs:{"label":"技术领域","prop":"tech_field"}},[_c('el-checkbox-group',{on:{"focus":function($event){return _vm.handleFocus('tech_field')},"blur":_vm.handleBlur},model:{value:(_vm.ruleForm.tech_field),callback:function ($$v) {_vm.$set(_vm.ruleForm, "tech_field", $$v)},expression:"ruleForm.tech_field"}},[_vm._l((_vm.techfieldList),function(item,key,index){return [_c('el-checkbox-button',{key:key,attrs:{"label":item}},[_vm._v(_vm._s(key))])]})],2)],1),_c('div',{staticClass:"lineBox"}),_c('el-form-item',{style:({
          ..._vm.formItemStyle,
          'border-bottom':
            _vm.focusKey === 'company' ? '1px solid #2885FF' : '0px ',
        }),attrs:{"label":"单位名称","prop":"company"}},[_c('el-input',{attrs:{"placeholder":_vm.placeholderName.unitName},on:{"focus":function($event){return _vm.handleFocus('company')},"blur":_vm.handleBlur},model:{value:(_vm.ruleForm.company),callback:function ($$v) {_vm.$set(_vm.ruleForm, "company", $$v)},expression:"ruleForm.company"}})],1),_c('div',{staticClass:"lineBox"}),_c('el-form-item',{staticClass:"shengshiqu",style:({
          ..._vm.formItemStyle,
          'border-bottom':
            _vm.focusKey === 'address' ? '1px solid #2885FF' : '0px ',
        }),attrs:{"label":"省市区","prop":"address"}},[_c('el-cascader',{staticStyle:{"width":"100%"},attrs:{"size":"large","popper-class":"","options":_vm.options,"clearable":"","placeholder":"请选择省市区"},on:{"change":_vm.handleChange,"focus":function($event){return _vm.handleFocus('address')},"blur":_vm.handleBlur},model:{value:(_vm.ruleForm.address),callback:function ($$v) {_vm.$set(_vm.ruleForm, "address", $$v)},expression:"ruleForm.address"}})],1),_c('div',{staticClass:"lineBox"}),_c('el-form-item',{staticClass:"detail-address",style:({
          ..._vm.formItemStyle,
          'border-bottom':
            _vm.focusKey === 'addressDetail' ? '1px solid #2885FF' : '0px ',
        }),attrs:{"label":"详细地址"}},[_c('el-input',{attrs:{"placeholder":"请输入详细地址"},on:{"focus":function($event){return _vm.handleFocus('addressDetail')},"blur":_vm.handleBlur},model:{value:(_vm.addressDetail),callback:function ($$v) {_vm.addressDetail=$$v},expression:"addressDetail"}})],1),_c('div',{staticClass:"lineBox"}),_c('el-form-item',{style:({
          ..._vm.formItemStyle,
          'border-bottom':
            _vm.focusKey === 'contact' ? '1px solid #2885FF' : '0px ',
        }),attrs:{"label":"联系人","prop":"contact"}},[_c('el-input',{attrs:{"placeholder":_vm.placeholderName.linkman},on:{"focus":function($event){return _vm.handleFocus('contact')},"blur":_vm.handleBlur},model:{value:(_vm.ruleForm.contact),callback:function ($$v) {_vm.$set(_vm.ruleForm, "contact", $$v)},expression:"ruleForm.contact"}})],1),_c('div',{staticClass:"lineBox"}),_c('el-form-item',{style:({
          ..._vm.formItemStyle,
          'border-bottom':
            _vm.focusKey === 'mobile' ? '1px solid #2885FF' : '0px ',
        }),attrs:{"label":"联系电话","prop":"mobile"}},[_c('el-input',{attrs:{"placeholder":_vm.placeholderName.telphone},on:{"focus":function($event){return _vm.handleFocus('mobile')},"blur":_vm.handleBlur},model:{value:(_vm.ruleForm.mobile),callback:function ($$v) {_vm.$set(_vm.ruleForm, "mobile", $$v)},expression:"ruleForm.mobile"}})],1),_c('div',{staticClass:"lineBox"}),_c('el-form-item',{style:({
          ..._vm.formItemStyle,
          'border-bottom': _vm.focusKey === 'memo' ? '1px solid #2885FF' : '0px ',
        }),attrs:{"label":"需求描述","prop":"memo"}},[_c('el-input',{staticClass:"textarea",attrs:{"type":"textarea","rows":_vm.rows,"placeholder":_vm.placeholderName.description,"autosize":{ minRows: 1, maxRows: 99},"maxlength":"1000"},on:{"focus":function($event){return _vm.handleFocus('memo')},"blur":_vm.handleBlur},model:{value:(_vm.ruleForm.memo),callback:function ($$v) {_vm.$set(_vm.ruleForm, "memo", $$v)},expression:"ruleForm.memo"}})],1),_c('div',{staticClass:"lineBox"}),_c('el-form-item',{staticClass:"subContainer"},[_c('el-button',{staticClass:"submitBtn btn",on:{"click":function($event){return _vm.submitForm('ruleForm')}}},[_vm._v("提交需求")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }