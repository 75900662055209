<template>
  <ContainerCard :locationMsg="locationMsg" width="70">
    <div class="IssueDemand">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <!-- <h2>发布需求</h2> -->
        <div class="IssueDemand-title">
          <img src="../../assets/images/信息资讯.png" alt="" />
          <span>发布需求</span>
        </div>
        <el-form-item
          label="需求名称"
          prop="name"
          :style="{
            ...formItemStyle,
            'border-bottom': focusKey === 'name' ? '1px solid #2885FF' : '0px ',
          }"
        >
          <el-input
            v-model="ruleForm.name"
            :placeholder="placeholderName.demandName"
            @focus="handleFocus('name')"
            @blur="handleBlur"
          ></el-input>
        </el-form-item>
        <div class="lineBox"></div>
        <el-form-item
          label="技术领域"
          prop="tech_field"
          :style="{
            'border-bottom':
              focusKey === 'tech_field' ? '1px solid #2885FF' : '0px ',
            padding: '24px 0px 12px 0px',
            'margin-bottom': '0px',
          }"
          class="technology"
        >
          <el-checkbox-group
            v-model="ruleForm.tech_field"
            @focus="handleFocus('tech_field')"
            @blur="handleBlur"
          >
            <template v-for="(item, key, index) in techfieldList">
              <el-checkbox-button :key="key" :label="item">{{
                key
              }}</el-checkbox-button>
            </template>
          </el-checkbox-group>
        </el-form-item>
        <div class="lineBox"></div>
        <el-form-item
          label="单位名称"
          prop="company"
          :style="{
            ...formItemStyle,
            'border-bottom':
              focusKey === 'company' ? '1px solid #2885FF' : '0px ',
          }"
        >
          <el-input
            v-model="ruleForm.company"
            :placeholder="placeholderName.unitName"
            @focus="handleFocus('company')"
            @blur="handleBlur"
          ></el-input>
        </el-form-item>
        <div class="lineBox"></div>
        <el-form-item
          label="省市区"
          prop="address"
          :style="{
            ...formItemStyle,
            'border-bottom':
              focusKey === 'address' ? '1px solid #2885FF' : '0px ',
          }"
          class="shengshiqu"
        >
          <el-cascader
            style="width: 100%"
            size="large"
            popper-class=""
            :options="options"
            v-model="ruleForm.address"
            @change="handleChange"
            clearable
            placeholder="请选择省市区"
            @focus="handleFocus('address')"
            @blur="handleBlur"
          >
          </el-cascader>
          <!-- <el-input v-model="addressDetail" placeholder="请输入具体地址"></el-input> -->
        </el-form-item>
        <div class="lineBox"></div>
        <el-form-item
          label="详细地址"
          :style="{
            ...formItemStyle,
            'border-bottom':
              focusKey === 'addressDetail' ? '1px solid #2885FF' : '0px ',
          }"
          class="detail-address"
        >
          <el-input
            v-model="addressDetail"
            placeholder="请输入详细地址"
            @focus="handleFocus('addressDetail')"
            @blur="handleBlur"
          ></el-input>
        </el-form-item>
        <div class="lineBox"></div>
        <el-form-item
          label="联系人"
          prop="contact"
          :style="{
            ...formItemStyle,
            'border-bottom':
              focusKey === 'contact' ? '1px solid #2885FF' : '0px ',
          }"
        >
          <el-input
            v-model="ruleForm.contact"
            :placeholder="placeholderName.linkman"
            @focus="handleFocus('contact')"
            @blur="handleBlur"
          ></el-input>
        </el-form-item>
        <div class="lineBox"></div>
        <el-form-item
          label="联系电话"
          prop="mobile"
          :style="{
            ...formItemStyle,
            'border-bottom':
              focusKey === 'mobile' ? '1px solid #2885FF' : '0px ',
          }"
        >
          <el-input
            v-model="ruleForm.mobile"
            :placeholder="placeholderName.telphone"
            @focus="handleFocus('mobile')"
            @blur="handleBlur"
          ></el-input>
        </el-form-item>
        <div class="lineBox"></div>
        <el-form-item
          label="需求描述"
          prop="memo"
          :style="{
            ...formItemStyle,
            'border-bottom': focusKey === 'memo' ? '1px solid #2885FF' : '0px ',
          }"
        >
          <el-input
            type="textarea"
            :rows="rows"
            v-model="ruleForm.memo"
            :placeholder="placeholderName.description"
            @focus="handleFocus('memo')"
            @blur="handleBlur"
            class="textarea"
            :autosize="{ minRows: 1, maxRows: 99}"
            maxlength="1000"
          ></el-input>
        </el-form-item>
        <div class="lineBox"></div>
        <el-form-item class="subContainer">
          <el-button class="submitBtn btn" @click="submitForm('ruleForm')"
            >提交需求</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </ContainerCard>
</template>
<script>
import Location from "../../components/Location.vue";
import { regionData, CodeToText } from "element-china-area-data";
import ContainerCard from "../../components/newContainerCard.vue";
import { mapGetters } from "vuex";
import { objDeepCopy } from "../../common/utils/tool.js";
import { publishDemand } from "../../api/demand/demand.js";
import { Message } from "element-ui";

export default {
  computed: {
    ...mapGetters(["techfieldList"]),
  },
  data() {
    return {
      focusKey: "",
      ruleForm: {
        name: "", // 需求名称
        tech_field: [], //技术领域
        company: "", // 单位名称
        address: "", // 地址
        contact: "", //联系人
        mobile: "", // 联系电话
        memo: "", // 需求描述
      },
      placeholderName:{
        demandName:'请输入需求名称',
        unitName:'请输入单位名称',
        linkman:'请输入联系人',
        telphone:'请输入联系电话',
        description:'请输入需求描述',
      },
      addressDetail: "",
      options: regionData,
      rows: 1,
      formItemStyle: {
        "border-bottom": "1px solid #F1F1F1",
        padding: "12px 0px",
        "margin-bottom": "0px",
        "box-sizing": "border-box",
      },
      rules: {
        name: [
          { required: true, message: "请输入需求名称", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
        company: [
          { required: true, message: "请输入单位名称", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
        tech_field: [
          { required: false, message: "请选择技术领域", trigger: "blur" },
        ],
        address: [{ required: false, message: "请输入地址", trigger: "blur" }],
        contact: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入联系 电话", trigger: "blur" },
        ],
        memo: [{ required: true, message: "请输入需求描述", trigger: "blur" }],
        // desc: [{ required: true, message: "请填写活动形式", trigger: "blur" }],
      },
      locationMsg: [
        { name: "找合作", router: "demand" },
        { name: "发布需求", router: "demand/issueDemand" },
      ],
    };
  },
  methods: {
    submitForm(formName) {
      let that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");
          let totalAddress = [];
          let address = that.ruleForm.address;
          let params = objDeepCopy(that.ruleForm);
          params.tech_field = that.ruleForm.tech_field.join(",");
          address &&
            address.forEach((item) => {
              totalAddress.push(CodeToText[item]);
            });
          totalAddress.push(that.addressDetail);
          let addr = totalAddress.join(",");
          params.address = addr;
          params.address = addr;
          // console.log("form", params);
          that.submitRequest(params);
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
      this.placeholderName = '';
    },
    async submitRequest(params) {
      let res = await publishDemand(params);
      let that = this;
      if (res.success) {
        Message.success("发布成功");
        that.$router.replace("/Demand");
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleFocus(key) {
      console.log("这个key是:",key)
      this.focusKey = key;
      if(this.focusKey == 'name') {
        this.placeholderName.demandName = '';
      }
      else if (this.focusKey == 'company') {
        this.placeholderName.unitName = '';
      }
      else if (this.focusKey == 'contact') {
        this.placeholderName.linkman = '';
      }
      else if (this.focusKey == 'mobile') {
        this.placeholderName.telphone = '';
      }
      else if (this.focusKey == 'memo') {
        this.placeholderName.description = '';
      }
    },
    handleBlur() {
      this.focusKey = "";
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    handleChange(value) {
      // console.log(value);
    },
    clearPlaceholder(){
      this.placeholderName.demandName = '';
      this.placeholderName.unitName = '';
    }
  },
  components: { Location, ContainerCard },
  watch: {
    "ruleForm.memo": {
      handler(newVal) {
        console.log("这个值是:",newVal)
        if (newVal && newVal.length > 0) {
          if (newVal.length % 50 == 0 && this.rows <= 12) {
            this.rows = newVal.length / 50 + 1;
          }
        } else {
          this.rows = 1;
        }
      },
    },
    deep: true,
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-input__inner {
  border: 0px;
}
::v-deep .el-textarea__inner {
  border: 0px;
}
::v-deep textarea {
  resize: none;
}
::v-deep .el-checkbox-button__inner {
  border: 1px solid #f1f1f1;
  margin-right: 12px;
  margin-bottom: 12px;
  border-radius: 17.5px;
  color: #101010;
  font-weight: 600;
  font-family: 思源黑体 CN Regular;
  &:hover {
    border: 1px solid #2885ff;
    color: #2885ff;
  }
}
::v-deep .el-checkbox-button:first-child .el-checkbox-button__inner {
  border: 1px solid #f1f1f1;
  border-radius: 17.5px;
  &:hover {
    border: 1px solid #2885ff;
    color: #2885ff;
  }
}
::v-deep .el-cascader-node {
  width: 194px;
}
::v-deep .el-checkbox-button:last-child .el-checkbox-button__inner {
  // border-left: 0px solid #F1F1F1;
  border-radius: 17.5px;
  &:hover {
    border: 1px solid #2885ff;
    color: #2885ff;
  }
}

.IssueDemand {
  width: 756px;
  font-family: Medium;
  font-weight: 500;
  color: #101010;
  margin-top: 20px;
  .IssueDemand-title {
    box-sizing: border-box;
    height: 64px;
    width: 756px;
    // padding: 24px 30px;
    img {
      width: 8px;
      height: 10px;
      margin-right: 8px;
    }
    span {
      color: #101010;
      font-size: 16px;
      font-weight: 600px;
      font-family: Medium;
      line-height: 24px;
    }
  }
}
.el-button:focus,
.el-button:hover {
  background: #14569f;
  border-radius: 4px;
  font-size: 16px;
  font-family: Medium;
  font-weight: 500;
  color: #ffffff;
}
.el-form {
  width: 100%;
  margin: 0 auto;
}
h2 {
  text-align: center;
  font-weight: normal;
  font-size: 28px;
  font-family: Medium;
  font-weight: 500;
  color: #101010;
  margin-bottom: 20px;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.subContainer {
  // display: ;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 58px;
}
.submitBtn {
  width: 88px;
  height: 34px;
  background: #005fdb;
  border-radius: 6px;
  font-size: 14px;
  font-family: Regular;
  font-weight: 500;
  color: #ffffff;
  padding: 0px;
}
.lineBox {
  width: 696px;
  height: 1px;
  background: #f1f1f1;
}
::v-deep .IssueDemand .el-form-item__label {
    text-align: start;
    padding-left: 0px;
}
::v-deep .IssueDemand .el-form-item:nth-child(2) {
  .el-form-item__label {
    padding-left: 0px;
  }
}

::v-deep .IssueDemand .el-form-item:nth-child(3) {
  .el-form-item__label {
    padding-left: 0px;
  } 
}
::v-deep .IssueDemand .technology .el-form-item__label {
    padding-left: 10px;
}
::v-deep .IssueDemand .shengshiqu .el-form-item__label {
    padding-left: 10px;
}

::v-deep .IssueDemand .detail-address .el-form-item__label {
    padding-left: 10px;
}
::v-deep .el-form-item  .el-form-item__content .el-form-item__error {
  top: 60%;
}
.checkbox-group {
  padding: 0 15px;
::v-deep .IssueDemand .detail-address .el-form-item__label {
    // background: red;
    padding-left: 10px;
}

}

// ::v-deep .el-input__inner:focus::placeholder{
//   // background: red;
//   // color: blue;
//   // opacity: 0;

  

::v-deep .el-form-item__error {
  top: 36%;
}


::v-deep .el-form-item__error {
  top: 30%;
}
::v-deep .el-input__inner:focus::placeholder{
  // background: red;
  // color: blue;
  // opacity: 0;

  
}
// ::v-deep .locationList {
//   margin-left:0px;
// }
::v-deep .locationList{
  position: absolute;
  left: -27px;
}
::v-deep .el-textarea__inner {
  font-weight: 500;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  // font-size: 12px;
}

.textarea{
  // color: red;
}
</style>
<style>
@media screen and (max-width: 768px) {
}
</style>